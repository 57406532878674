@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
*{
    font-family: 'Poppins';
    font-family: 'Poppins';
    /* transition: all 1s; */
}
.skills_main{
    /* margin-top: 8%; */
    padding-top: 8%;
    /* margin-left: 0.5rem;
    margin-right: 0.5rem; */
    animation:skillsTransit forwards 1s;
}
@keyframes skillsTransit {
    0%{opacity: 0;}
    100%{opacity: 1;}
}
.title .skills_title{
    font-family: 'Poppins';
    font-size: 35px;
    text-align: center;
}
.skills{
    display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.skill_sub_main{
    display: grid;
    grid-template-columns: 1fr 15fr;
    flex: 0 0 40%;
    background: teal;
    color: white;
    padding: 20px;
    margin: 10px;
    transition: transform 0.6s;
    transform-style: preserve-3d;
}

.skill_title{
    background-color: #ffc20e;
    font-size: 25px;
    width:100px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}
.skill_title p{
    margin: auto;
    transform: rotateZ(270deg);
    margin-bottom: auto;
    vertical-align: middle;
    color: white;
    width: 100%;
}
#otherskill{
    font-size: 40px;
}
.frontend{
    font-family: 'Poppins';
    display: grid;
    grid-template-columns: 1fr;
    padding: 1rem 0.5rem;
    
}
.first{
    display: flex;
    justify-content: space-between;
    padding: 0;
    margin: 0;
}
.level{
    margin: 0;
    padding: 0;
    width: 100%;
    height: 0.5rem;
    background-color: greenyellow;
}
.level p{    
    height: 0.5rem;
    background-color: green;
}

.lhtml p{
    width: 90%;
    height: 0.5rem;
    background-color: green;
    animation: loading90 1.5s forwards;
}
.lcss p{
    width: 80%;
    animation: loading80 1.5s forwards;
}
.ljavascript p{
    width: 75%;
    animation: loading75 1.5s forwards;
}
.lreact p{
    width: 60%;
    animation: loading60 1.5s forwards;
}
.l50 p{
    animation: loading50 1.5s forwards;

}

.first p{
    margin: 1rem 0 0 0;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.customLevel{
    margin: 0;
    padding: 0;
    width: 100%;
    height: 0.5rem;
    background-color: greenyellow;
}
.customLevel p{
    margin: 0;
    padding: 0;
    height: 0.5rem;
    background-color: green;
}
@keyframes customLoading{
    0%{
        width: 0%;
    }
    100%{
        width: var(--width);
    }
}
/* Animation */
@keyframes loading90{
    0%{
        width: 0%;
    }
    100%{
        width: 90%;
    }
}
@keyframes loading80{
    0%{
        width: 0%;
    }
    100%{
        width: 80%;
    }
}
@keyframes loading75{
    0%{
        width: 0%;
    }
    100%{
        width: 75%;
    }
}
@keyframes loading60{
    0%{
        width: 0%;
    }
    100%{
        width: 60%;
    }
}
@keyframes loading50{
    0%{
        width: 0%;
    }
    100%{
        width: 50%;
    }
}

/* Certificates */
.certificates_main{
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
  margin: 5rem;
  padding-bottom: 2rem;
}
.certificate{
    display: grid;
    grid-template-columns: 2fr 8fr;
    padding: 0.5rem;
}
.certificatereverse{
    display: grid;
    grid-template-columns: 8fr 2fr;
    padding: 0.5rem;
}
.certificate .left{
    position: relative;
}
.certificate .left img{
    width: 100%;
    height: 170px;
    object-fit: cover;
    transition: all 0.5s;
    /* overflow: hidden; */
    border-radius: 5%;
    position: relative;
    animation: animate_certleft 1s forwards;
}
.certificate .left img:hover{
    transform: scale(1.05);
}
.cover{
    background-color: rgba(128, 128, 128, 0.229);
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 5%;
    transition: all 0.5s;
    cursor: pointer;
}
.cover:hover  {
    transform: scale(1.05);
}
.cover i{
    font-size: 25px;
    color: #ffc20e;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.certificate .right{
    padding: 0.7rem;
}
.certificate .right .cert_title{
    font-weight: bold;
    font-size: 20px;
}
.certificate .right div{
    position: relative;
    animation: animate_certright 1s forwards;
}
.info p{
    text-align: justify;
}
.image_display{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffc20e;
    padding: 0.5rem;
    display: none;
    transition: all 1s;
}
.image_display img{
    width: calc(100vh - 100px);
    max-height: 500px;
}
.image_display p{
    color: red;
    cursor: pointer;
    font-size: 25px;
    padding: 0.5rem;
    position: absolute;
    top: -5%;
    right: 3%;
    background-color: rgba(128, 128, 128, 0.495);
    border-radius: 50%;
}
/* animation */
@keyframes animate_certleft {
    0%{
        opacity: 0;
        left: -15%;
    }
    100%{
        opacity: 1;
        left: 0;
    }
}
@keyframes animate_certright {
    0%{
        opacity: 0;
        right: -15%;
    }
    100%{
        opacity: 1;
        right: 0;
    }
}
/* media queries */
@media (max-width:800px) {
    .skills{
        flex-direction: column;
        margin: 2rem;
    }
    .certificates_main{
        margin: 5rem;
        gap: 2rem;
        grid-template-columns: 1fr;
    }
    .certificate{
        grid-template-columns: 1fr;
    }
}
@media (max-width:500px) {
    .image_display img{
        width: 400px;
    }
    .certificates_main{
        margin: 3rem;
    }
}
@media (max-width:420px) {
    .skills{
        margin: 0.5rem;
    }
    .skill_sub_main{
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 9fr;
    }
    .skill_title{
        background-color: #ffc20e;
        font-size: 15px !important;
        text-align: center;
    }
    .skill_title p{
        margin: auto;
        transform: rotate(0deg);
        margin-top: 0%;
        margin-bottom: auto;
        vertical-align: middle;
        color: white;
        padding: 0.5rem;
    }
    #otherskill{
        font-size: 15px;
    }
    .certificates_main{
        margin: 1rem;
    }
    .image_display img{
        width: 300px;
    }
}