
.contact_main{
    padding-top: 9%;
    animation: contactTransit 1s forwards;
}
.contact_title{
    font-family: 'Poppins';
    font-size: 35px;
    text-align: center;
}
@keyframes contactTransit {
    0%{opacity: 0;}
    100%{opacity: 1;}
}
.contact_content{
    width: 75%;
    /* height: 90vh; */
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 1rem;
    gap: 5rem;
    margin-bottom: 2%;
}

.form_details{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem;
}
.form_details button{
    margin-left: 0;
    width: 50%;
    margin: auto;
    font-family: 'Poppins';
}
.form_details input, textarea{
    padding: 0.5rem;
    border: 2px solid #e6e7ef;
    outline: none;
    border-radius: 8px;
}
.contact_name{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;
}
.sub_name{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
.contact_left span span{
    color: red;
}
.icons_contact{
    text-align: center;
    margin-top: 1rem;
    padding: 0.3rem;
}
.icons_contact span{
    font-weight: bold;
}
.contact_right_title{
    font-size: 35px;
}
.contact_info, .other_contact_info span{
    font-weight: 100;
    color: gray;
}
.other_contact_info>p{
    font-size: 30px;
}
.services_provided{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin-top: 1rem;
    width: fit-content;
}
.contact_frontend,
.contact_backend{
    padding: 0.5rem;
    text-align: center;
}
.contact_frontend p,
.contact_backend p{
    font-size: 10px;
}
/* media queries */
@media (max-width:1200px) {
    .contact_content{
        width: 85%;
    }
    .contact_main{
        padding-top: 10%;
    }
}
@media (max-width:805px) {
    .contact_content{
        display: flex;
        flex-direction: column-reverse;
        text-align: center;
    }
    .sub_name span{
        text-align: left;
    }
}
@media (max-width:420px) {
    .contact_name{
        display: grid;
        grid-template-columns: 1fr;
        gap: 0.3rem;
    }
}