 /* footer  */
 footer{
    font-family: 'Poppins';
    width: 100%;
    padding: 25px;
    background-color: rgba(0, 0, 0, 0.866);
    /* background-color: transparent; */
    text-align: center;
    box-sizing: border-box;
}
footer h3{
    font-size: 25px;
    color: white;
}
footer i{
    font-size: 35px;
    padding: 10px; 
    transition: all .5s;
}
footer a{
    text-decoration: none;
    color: white;
}
footer p{
    text-decoration: none;
    color: white;
    
}
footer a i:hover{
    transform: scale(1.15,1.15);
}