@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
*{
    font-family: 'Poppins';
    font-family: 'Poppins';
    transition: all 1s;
}
.projects_section{
    padding-top: 5%;
    animation: trans 1s forwards;
}
@keyframes trans {
    0%{opacity: 0;}
    100%{opacity: 1;}
}
.project_title p{
    font-family: 'Poppins';
    font-size: 35px;
    text-align: center;
}
.projects_main{
    width: 100%;
    text-align: center;
    margin: 0.5rem auto;
}
.btn-main-project{
    width: 25%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    justify-content: center;
    align-items: center;
    margin: auto;
}
.btn-main-project button{
    margin: auto;
    width: fit-content;
}

/* project */
.projects_category{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2rem;
    width: 75%;
    margin: 4rem auto;
    transform: none;
}
.project{
    display: grid;
    grid-template-columns: 1fr;
    padding: 1rem;
}

.project_image img{
    width: 100%;
    height: 200px;
    margin-bottom: 1rem;
}

.project button{
    margin: auto;
}
.project_info{
    text-align: justify;
}
.widthwebsites{
    width: 75%;
    grid-template-columns: 1fr 1fr 1fr;
}
.widthAndroid{
    width: 50%;
    grid-template-columns: 1fr 1fr;
}
.widthCLI{
    width:50%;
    grid-template-columns: 1fr 1fr;
}
/* media queries */
@media (max-width:1200px) {
    .btn-main-project{
        width: 50%;
    }
    .projects_category{
        width: 90%;
    }
    .widthwebsites{
        width: 90%;
        grid-template-columns: 1fr 1fr 1fr;
    }
    .widthAndroid{
        width: 70%;
        grid-template-columns: 1fr 1fr;
    }
    .widthCLI{
        width:40%;
        grid-template-columns: 1fr;
    }
}
@media (max-width:820px) {
    .projects_category{
        width: 90%;
        grid-template-columns: 1fr 1fr;
    }
    .widthwebsites{
        grid-template-columns: 1fr 1fr;
    }
    .widthAndroid{
        grid-template-columns: 1fr 1fr;
    }
    .widthCLI{
        width: 40%;
        grid-template-columns: 1fr;
    }
}
@media (max-width:520px) {
    .projects_section{
        padding-top: 15%;
    }
    .btn-main-project{
        width: 100%;
    }
    .projects_category{
        width: 90%;
        grid-template-columns: 1fr;
    }
    .widthwebsites{
        grid-template-columns: 1fr;
    }
    .widthAndroid{
        grid-template-columns: 1fr;
    }
    .widthCLI{
        width: 90%;
        grid-template-columns: 1fr;
    }
}