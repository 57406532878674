html::-webkit-scrollbar{
    display: none;
}
html{
    scroll-behavior: smooth;
}
body{
    margin: 0;
    padding: 0;
    font-family: 'Courier New', Courier, monospace;
}
:root{
     --nav-color: black;
     --side-menu-text: #778497;
}

/* Buttons */

.btn{
    padding: 0.5rem;
    margin-left: 50%;
    font-family: 'Poppins';
    font-weight: bold;
    background-color: white;
    cursor: pointer;
    border-radius: 5px;
    border: none;
    transition: transform 0.3s;
}
.btn:hover{
    transform: scale(1.2,1.2);
    background: transparent;
}
/* Glowing Effect */
.glow-on-hover {
    
    border: none;
    outline: none;
    color: #111;
    background: #fff;
    position: relative;
    z-index: 0;
    border-radius: 10px;
}

.glow-on-hover:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 500%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 1;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;

}

/* .glow-on-hover:active {
    color: #000
}

.glow-on-hover:active:after {
    background: transparent;
} */

.glow-on-hover:hover:before {
    opacity: 1;
}

.glow-on-hover:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #fff;
    left: 0;
    top: 0;
    border-radius: 10px;
}

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}
/* current page */
.curr_page_on{
    color: #ffc20e !important;
}
.curr_page_on_menu{
    color: #ffc20e !important;
    letter-spacing: 0.2rem !important;
    /* border-bottom: 2px solid #ffc20e; */
}