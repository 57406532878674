@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

.home_page{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    padding: 2rem;
    padding-top: 1rem;
    animation: fadedIn 1.5s forwards;
}
.home-left{
    padding-top: 10%;
    display: grid;
    grid-template-columns: 1fr;
    font-family:  'Poppins', sans-serif;
    gap: 1rem;
}
.title .main{
    font-size: 50px;
    font-weight: bold;
    text-align: left;
}
.btns{
    width: 50%;
    display: flex;
    gap: 1rem;
}
.others{
    width: 80%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
}
.others div p{
    width: fit-content;
    text-align: center;
}
.statistics{
    width: 80%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    align-items: center;
    background-color: var(--nav-color);
    color: black;
    z-index: 2;
}
.statistics .left{
    text-align: center;
}

.projects p span{
    font-size: 25px;
}
.contact span{
    font-size: 25px;
}
.home-right{
    font-family: 'Poppins', sans-serif;
    position: relative;
    padding-top: 10%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.intro{
    cursor: pointer;
    width: 80%;
    padding: 0.8rem;
    text-align: justify;
}
.personal_img{
    margin-top: 10%;
}
.personal_img img{
    width: 300px;
    border-radius: 50% 50% 51% 50% / 35% 34% 66% 65% ;
}

.html_icon_class{
    width: 50px;
    position: absolute;
    animation: animation_html  1s forwards;
}
#css_icon{
    width: 50px;
    position: absolute;
    animation: animation_css  1s forwards;
}
#react_icon{
    width: 40px;
    position: absolute;
    animation: animation_react  1s forwards;
}
#js_icon{
    width: 40px;
    position: absolute;
    animation: animation_js  1s forwards;
}
#android_icon{
    width: 40px;
    position: absolute;
    animation: animation_and  1s forwards;
}
#firebase_icon{
    width: 40px;
    position: absolute;
    animation: animation_fire  1s forwards;
}

/* Animations */
@keyframes fadedIn {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}
@keyframes animation_html {
    0%{
        opacity: 0.1;
        left: 0;
        top: 10%;
    }
    100%{
        opacity: 1;
        left: 15%;
        top: 25%;
    }
}
@keyframes animation_css {
    0%{
        opacity: 0.1;
        left: 0;
        top: 35%;
    }
    100%{
        opacity: 1;
        left: 8%;
        top: 41%;
    }
}
@keyframes animation_js {
    0%{
        opacity: 0.1;
        left: 0;
        top: 65%;
    }
    100%{
        opacity: 1;
        left: 15.5%;
        top: 57%;
    }
}

@keyframes animation_react {
    0%{
        opacity: 0.1;
        left: 100%;
        top: 10%;
    }
    100%{
        opacity: 1;
        right: 15%;
        top: 25%;
    }
}
@keyframes animation_and {
    0%{
        opacity: 0.1;
        left: 100%;
        top: 35%;
    }
    100%{
        opacity: 1;
        right: 8%;
        top: 41%;
    }
}
@keyframes animation_fire {
    0%{
        opacity: 0.1;
        left: 100%;
        top: 65%;
    }
    100%{
        opacity: 1;
        right: 15%;
        top: 57%;
    }
}

/* media Queries */
@media (max-width:1200px) {
    .home_page{
       margin-left: 0.5rem;
        margin-right: 0.5rem;
    }
    .personal_img img{
        width: 200px;
        border-radius: 50% 50% 51% 50% / 35% 34% 66% 65% ;
    }
    .personal_img{
        margin-top: 20%;
    }
    .statistics{
        width: 90%;
        grid-template-columns: 1fr 2fr;
    }
}
@media (max-width:800px) {
    .type-notice{
        display: none;
    }
    .home_page{
        display: grid;
        grid-template-columns: 1fr;
        gap: 2rem;
        align-items: center;
        justify-content: center;
    }
    .home-left{
        padding: 0;
        text-align: center;
    }
    .home-right{
        padding-top: 0%;
        gap: 10rem;
    }

    .btns{
        width: 100%;
        align-items: center;
        justify-content: center;
    }
    .others{
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        justify-content: center;
        align-self: center;
    }
    .others div p{
        margin-left: auto;
        margin-right: auto;
    }
    .statistics{
        margin-left: auto;
        margin-right: auto;
    }
    .title .main{
        text-align: center;
    }
}

@media (max-width:550px) {
    .type-notice{
        display: none;
    }
    .home_page{
        margin-top: 10%;
        margin-left: 0;
        margin-right: 0;
        padding: 1rem;
        gap: 5rem;
    }
    .title .main{
        font-size: 30px;
        text-align: center;
    }
    .others{
        grid-template-columns: 1fr;
    }
    .statistics{
        width: 100%;
        grid-template-columns: 0.5fr 2fr;
        margin: 0;
    }
    .intro{
        width: 90%;
    }
    .personal_img{
        padding-top: 25%;
        margin-top: 0%;
    }
    .personal_img img{
        width: 200px;
        border-radius: 50% 50% 51% 50% / 35% 34% 66% 65% ;
    }
    .html_icon_class{
        animation: animation_html420  1s forwards;
    }
    #css_icon{
        animation: animation_css420  1s forwards;
    }
    #react_icon{
        animation: animation_react420  1s forwards;
    }
    #js_icon{
        animation: animation_js420  1s forwards;
    }
    #android_icon{
        animation: animation_and420  1s forwards;
    }
    #firebase_icon{
        animation: animation_fire420  1s forwards;
    }
}

/* Animations */
@keyframes animation_html420 {
    0%{
        opacity: 0.1;
        left: 0;
        top: 0%;
    }
    100%{
        opacity: 1;
        left: 10%;
        top: 2%;
    }
}
@keyframes animation_css420 {
    0%{
        opacity: 0.1;
        left: 5%;
        top: 22%;
    }
    100%{
        opacity: 1;
        left: 0%;
        top: 22%;
    }
}
@keyframes animation_js420 {
    0%{
        opacity: 0.1;
        left: 0;
        top: 52%;
    }
    100%{
        opacity: 1;
        left: 10%;
        top: 42%;
    }
}

@keyframes animation_react420 {
    0%{
        opacity: 0.1;
        left: 0;
        top: 0%;
    }
    100%{
        opacity: 1;
        right: 10%;
        top: 2%;
    }
}
@keyframes animation_and420 {
    0%{
        opacity: 0.1;
        right: 5%;
        top: 22%;
    }
    100%{
        opacity: 1;
        right: 0%;
        top: 22%;
    }
}
@keyframes animation_fire420 {
    0%{
        opacity: 0.1;
        left: 0;
        top: 52%;
    }
    100%{
        opacity: 1;
        right: 10%;
        top: 42%;
    }
}