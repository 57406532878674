@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
.about_main{
    padding: 5%;
    animation: transit 1s forwards;
}
.about_title p{
    font-family: 'Poppins';
    font-size: 35px;
    text-align: center;
}
.about_intro{
    padding: 1rem;
    cursor: pointer;
}
.about_content{
    width: 75%;
    margin: auto;
    display: grid;
    gap: 1rem;
    grid-template-columns:  1fr 1fr;
    align-items: center;
    justify-content: center;
}
.about_right{
    text-align: center;
    position: relative;
}
.about_right img{
    width: 300px;
    margin: auto;
    border-radius: 10% 10% 41% 42% / 10% 10% 29% 31%;
}
.image_bg{
    width: 390px;
    height: 390px;
    border-radius: 50%;
    background-color: #ffc20e;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: -1;
}
.name_about{
    font-size: 25px;
}
.name_about span{
    font-size: 30px;
}
.about_left button{
    padding: 1rem;
    font-family: 'Poppins';
    border-radius: 16px;
    margin: 0;
    margin-right: 1rem;
    margin-top: 1rem;
}

@keyframes transit {
    0%{opacity: 0;}
    100%{opacity: 1;}
}
@media (max-width:1200px){
    .about_content{
        width: 100%;
        gap: 2rem;
        text-align: center;
    }
    .about_intro{
        text-align: justify;
    }
}
@media (max-width:805px) {
    .about_main{
        padding-top: 10%;
    }
    .about_content{
        display: flex;
        flex-direction: column-reverse;
    }
    
}
@media (max-width:420px) {
    .about_right{
        margin-top: 1rem;
    }
    .about_right img{
        width: 200px;
    }
    .image_bg{
        width: 290px;
        height: 290px;
        top: 45%;
    }
}

