/* Notices */
.notices{
    width: 100%;
    height: fit-content;
    position: fixed;
    top: 0%;
    margin: 0;
    z-index: 9999;
    background-color: #ffc20e;
    text-align: center;
    color: #f2f2f2;
    padding: 0.5rem;
}

nav{
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    justify-content: space-around;
    grid-template-rows: 70px;
    align-items: center;
    width: 100%;
    color: white;
    background-color: var(--nav-color);
    position: fixed;
    z-index: 9999;
    top: 5%;
    border-bottom: 1px solid white;
}

.logo img{
    width: 120px;
    height: 100px;
    margin-left: 20%;
}

.nav-bar-content{
    list-style: none;
    display: flex;
    gap: 4rem;
    margin-left: 20%;
}

.side-bar-content li .a,
.nav-bar-content li .a{
    color: white;
    text-decoration: none;
    font-size: 20px;
    font-weight: bold;
    /* margin-right: 4rem; */
    transition: letter-spacing 0.5s;
}

.nav-bar-content li .a:hover{
    letter-spacing: 0.2rem;
    color: #ffc20e;
}
#contact_menu button{
    font-family: 'Poppins';
    letter-spacing: 1px;
}
.bars-icon{
    display: none;
    font-size: 30px;
    text-align: right;
    padding-right: 0.8rem;
    align-items: center;
    cursor: pointer;
}

/* side menu bar */
.side-menu{
    position: absolute;
    top: 0;
    right: -100%;
    width: 75%;
    height: 100vh;
    display: grid;
    grid-template-rows: 0.5fr 2fr 3fr 1fr;
    align-items: center;
    background-color: white;
    color: black;
    box-shadow: -4px 0px 20px rgb(32 54 86 / 20%);
    /* display: none; */
}

.side-bar-content li{
    width: 75%;
    border-bottom: 2px solid #f2f2f2;
    margin-bottom: 0.8rem;
    padding-bottom: 0.6rem;
}
.side-bar-content li .a{
    color: var(--side-menu-text);
    font-weight: 500;
}
.side-bar-content{
    list-style-type: none;
}
.side-logo img{
    width: 180px;
    margin-left: 10%;
}
.close-bt{
    text-align: right;
    padding-right: 8%;
    font-size: 25px;
    font-weight: bold;
    color: var(--side-menu-text);
    cursor: pointer;
}
.close-bt:hover{
    color:blue;
}
.social-media {
    text-align: center;
    display: flex;
    padding-left: 20%;
}
.social-media a{
    text-decoration: none;
    color: black;
    font-size: 25px;
    margin-right: 0.8rem;
}
.social-media a:hover{
    color: blue;
}

@keyframes side_bar_animation_open {
    0%{
        right: -100%;
    }
    100%{
        right: 0;
    }
}
@keyframes side_bar_animation_close {
    0%{
        right: 0;
    }
    100%{
        right: -100%;
    }
}

/* media queries  */
@media (max-width:950px) {
    .nav-bar-content{
        gap: 2rem;
        margin-left: 0%;
    }

}
@media (max-width:805px) {
    nav{
        grid-template-columns: 1fr 1fr;
        gap: 10%;
    }
    .nav-bar{
        display: none;
    }
    .left-item{
        display: none;
    }
    .bars-icon{
        display: block;
    }
    .side-menu{
        display: grid;
    }
}

